import {
  withStyles,
  Card,
  CardContent,
  CardActions,
  Modal,
  Button,
  Typography,
  Theme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, {Component, Fragment} from 'react';
// @ts-ignore
import {compose} from 'react-recompose';
import {withRouter} from 'react-router-dom';

const styles = ({spacing}: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCard: {
    width: '90%',
    maxWidth: 700,
    height: '85%',
    overflowY: 'scroll',
  },
  modalCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginTop: {
    marginTop: spacing(5),
  },
  textCenter: {
    margin: 'auto',
  },
  images: {
    width: '100%',
  },
  links: {
    textDecoration: 'none',
    color: 'black',
  },
  header: {
    marginTop: spacing(2),
  },
});

type HelpProps = {
  showModal?: boolean;
  handleChange: any;
  classes: any;
};
type HelpState = {
  showModal?: boolean;
};

class Help extends Component<HelpProps, HelpState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.showModal !== this.props.showModal) {
      this.setState({showModal: this.props.showModal});
    }
  }

  handleChange() {
    const parentHandler = this.props.handleChange;
    this.setState(
        {
          showModal: !this.state.showModal,
        },
        parentHandler,
    );
  }

  render() {
    const {classes} = this.props;
    const APP_VERSION = process.env.REACT_APP_VERSION;

    return (
      <Fragment>
        {this.state.showModal && (
          <Modal className={classes.modal} onClose={this.handleChange} open>
            <Card className={`${classes.modalCard} ${classes.marginTop}`}>
              <CardContent className={classes.modalCardContent}>
                <Typography variant="h6">About the app</Typography>
                <Typography> Version useful information </Typography>
                <Typography>App version: {APP_VERSION}</Typography>

                <Typography className={classes.header} variant="h6">
                  Developing Organization
                </Typography>
                <img
                  className={classes.images}
                  src={`${window.location.origin}/images/elitr.png`}
                  alt="HCSS Data Lab"
                />
              </CardContent>
              <CardActions>
                <Button size="small" onClick={this.handleChange}>
                  <ClearIcon />
                  Close
                </Button>
              </CardActions>
            </Card>
          </Modal>
        )}
      </Fragment>
    );
  }
}

// @ts-ignore
export default compose(withRouter, withStyles(styles))(Help);
