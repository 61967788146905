import {AppBar, Toolbar, Button, Typography, Theme, withStyles} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, {Component} from 'react';
// @ts-ignore
import {compose} from 'react-recompose';
import {Link} from 'react-router-dom';
// @ts-ignore

import Help from './help';

const styles = ({spacing, breakpoints}: Theme) => ({
  header: {
    backgroundColor: '#0D1C3D',
  },
  text: {
    fontSize: '4.5em',
    color: '#f50057',
  },
  headerButton: {
    position: 'fixed',
    top: spacing(-0.5),
    right: spacing(),
    color: '#f50057',
    [breakpoints.down('xs')]: {
      top: spacing(-1),
      right: spacing(0),
    },
  },
  link: {
    fontSize: '1.5em',
    color: 'white',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    textDecoration: 'none',
  },
  helpIcon: {
    fontSize: '4.5em',
    color: 'white',
  },
});

type AppHeaderProps = {
  classes: any;
};
type AppHeaderState = {
  showHelp?: boolean;
};

class AppHeader extends Component<AppHeaderProps, AppHeaderState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showHelp: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <AppBar position="static" className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <Button color="inherit" component={Link} to="/">
            <Typography variant="h6" color="inherit">
              Cyber Arms Watch
            </Typography>
          </Button>
        </Toolbar>

        <Button onClick={this.handleChange} className={classes.headerButton}>
          <HelpOutlineIcon color="secondary" aria-label="add" className={classes.helpIcon} />
        </Button>
        <Help handleChange={this.handleChange} showModal={this.state.showHelp} />
      </AppBar>
    );
  }
}

// @ts-ignore
export default compose(withStyles(styles))(AppHeader);
