import {CssBaseline, withStyles, Theme} from '@material-ui/core';
import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';

import MapManager from '../pages/mapManager';

import AppHeader from './appHeader';

const styles = ({spacing, breakpoints}: Theme) => ({
  main: {
    [breakpoints.down('xs')]: {
      padding: spacing(0.5),
    },
  },
});

const App = ({classes}: { classes: any }) => (
  <Fragment>
    <CssBaseline />

    <AppHeader />

    <main className={classes.main}>
      <Route exact path="/" component={MapManager} />
    </main>
  </Fragment>
);

export default withStyles(styles)(App);
