import { Typography, 
        Theme,
        withStyles,
        Grid,
        Chip,
        Button } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import {compose} from 'react-recompose';
import { Description } from '@material-ui/icons';


import CyberTable from './cybertable'

const styles = ({spacing}: Theme) => ({
  padding: {
    paddingBottom: spacing(1.5)
  },
  button: {
    width: "100%"
  }
});

type DetailsProps = {
  classes: any;
};
type DetailsState = {
  rows: any;
};

class Details extends Component<DetailsProps, DetailsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      rows: [
        { name: "test"},
        { name: "test2"}
      ]
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h5">Detail</Typography>

        {this.state.rows.length > 0 && (
          <Fragment>
            <Typography variant="h6" className={classes.padding}>Country A</Typography>

            <Grid container className={classes.padding}>
              <Grid item xs={6} className={classes.padding}>
                <Chip label="Free" variant="outlined" color="primary" />
              </Grid>
              <Grid item xs={6}>
                <Typography>30/100</Typography>
              </Grid>

              <Grid item xs={6} className={classes.padding}>
                <Chip label="Free" variant="outlined" color="primary" />
              </Grid>
              <Grid item xs={6}>
                <Typography>30/100</Typography>
              </Grid>

              <Grid item xs={6}>
                <Chip label="Free" variant="outlined" color="primary" />
              </Grid>
              <Grid item xs={6}>
                <Typography>30/100</Typography>
              </Grid>
            </Grid>

            <CyberTable data={ this.state.rows }></CyberTable>

            <Typography className={classes.padding}>Some very usefully further text</Typography>

            <Button variant="contained" endIcon={<Description />} className={classes.button}>
              View Report
            </Button>
          </Fragment>
        )}

      </Fragment>
    )
  }
}

// @ts-ignore
export default compose(withStyles(styles))(Details);
