import { Typography, 
        Theme,
        withStyles,
        Chip } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
// @ts-ignore
import {compose} from 'react-recompose';
import { scaleQuantize } from "d3-scale";

var quantizeScale = scaleQuantize()
	.domain([0, 100])

const styles = ({spacing, breakpoints}: Theme) => ({
  padding: {
    paddingBottom: spacing(2)
  }
});

type IndexViewProps = {
  classes: any;
};
type IndexViewState = {
};

class IndexView extends Component<IndexViewProps, IndexViewState> {
  constructor(props: any) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h5">Cyber Security Score</Typography>
        <Typography className={ classes.padding }>This is some nice and explaning text bli bla blo</Typography>

        <table>
          <tr>
            <td><Chip label="Free" variant="outlined" color="default" /></td>
            <td><Chip label="Medium" variant="outlined" color="primary" /></td>
            <td><Chip label="Not Free" variant="outlined" color="secondary" /></td>
          </tr>
          <tr>
            <td>Score: </td>
            <td>Score: </td>
            <td>Score: </td>
          </tr>
        </table>
      </Fragment>
    )
  }
}

// @ts-ignore
export default compose(withStyles(styles))(IndexView);