import React, { memo, Fragment } from "react";
import { scaleQuantize } from "d3-scale";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";

import worlddata from "../data/world-110m_enriched.json"

var quantizeScale = scaleQuantize()
	.domain([0, 100])
  .range(['lightblue', 'orange', 'lightgreen', 'pink']);

const MapChart = ({ setTooltipContent }) => {
  return (
    <Fragment>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        <ZoomableGroup>
          <Geographies geography={worlddata}>
            {({ geographies }) =>
              geographies.map((geo) => {
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      const { NAME } = geo.properties;

                      let score_pcr = "n/a"
                      if(geo.properties.DATA && Array.isArray(geo.properties.DATA)) {
                        score_pcr = geo.properties.DATA[0].Score_PCR
                      } 

                      setTooltipContent(
                        `${NAME} - ${score_pcr}`
                      );
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    fill={quantizeScale(geo.properties.DATA ? geo.properties.DATA.Score_PCR : "#EEE")}
                    style={{
                      default: {
                        stroke: "#FFFFFF",
                        strokeWidth: 0.5
                      },
                      hover: {
                        fill: "#0D1D41",
                        outline: "none"
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none"
                      }
                    }}
                  />
                )
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </Fragment>
  );
};

export default memo(MapChart);
