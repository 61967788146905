import {
  withStyles,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Theme
} from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  padding: {
    paddingBottom: theme.spacing(2)
  }
});

class CyberTable extends Component {
  constructor() {
    super()

    this.state = {
      data: []
    }

    this.updateData = this.updateData.bind(this)
  }

  componentDidUpdate() {
    this.updateData()
  }

  componentDidMount() {
    this.updateData()
  }

  updateData() {
    const { data } = this.props

    if (this.state.data !== this.props.data) {
      this.setState({
        data: data
      })
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.padding}>
        {this.state.data.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="detail-score-table">
              <TableBody>
                {this.state.data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      name
                    </TableCell>
                    <TableCell scope="row">
                      {row.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    )
  }
}

export default compose(withRouter, withStyles(styles))(CyberTable);