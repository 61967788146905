import {Typography,
        Theme,
        withStyles, 
        Grid,
      Card,
    CardContent} from '@material-ui/core';
import React, {Component, Fragment} from 'react';
// @ts-ignore
import {compose} from 'react-recompose';
import {withRouter} from 'react-router-dom';
import ReactTooltip from "react-tooltip";

import ErrorSnackbar from '../components/errorSnackbar';
import IndexView from '../components/indexView';
import Details from '../components/details';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import MapChart from '../components/mapChart';

const styles = ({spacing, breakpoints}: Theme) => ({
  main: {
    height: "100vh"
  },
  sidebar: {
    marginTop: spacing(5),
    paddingRight: spacing(1.5)
  },
  cards: {
    margin: spacing(2)
  }
})

type MapManagerProps = {
  classes: any
};
type MapManagerState = {
  content: string;

  loading: boolean;
  success: string;
  error: string;
};

class MapManager extends Component<MapManagerProps, MapManagerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      content: "",

      loading: false,
      success: '',
      error: '',
    };

    this.setContent = this.setContent.bind(this);
  }

  setContent(text: string) {
    this.setState({
      content: text
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container>
        <Grid item xs={12} md={8} className={ classes.main }>
          <MapChart setTooltipContent={this.setContent} />
          <ReactTooltip>{this.state.content}</ReactTooltip>
        </Grid>

        <Grid item xs={12} md={4} className={ classes.sidebar }>
          <Card className={ classes.cards }>
            <CardContent>
              <IndexView></IndexView>
            </CardContent>
          </Card>
          <Card className={ classes.cards }>
            <CardContent>
              <Details></Details>
            </CardContent>
          </Card>
        </Grid>

        {/* Flag based display of error snackbar */}
        {this.state.error.length > 0 && (
          <ErrorSnackbar onClose={() => this.setState({error: ''})} message={this.state.error} />
        )}

        {/* Flag based display of loadingbar */}
        {this.state.loading && <LoadingBar />}

        {/* Flag based display of info snackbar */}
        {this.state.success.length > 0 && (
          <InfoSnackbar
            onClose={() => this.setState({success: ''})}
            message={this.state.success}
          />
        )}
      </Grid>
    );
  }
}

// @ts-ignore
export default compose(
  withRouter,
  withStyles(styles),
  )(MapManager);
